import { useState, useEffect, useRef, memo, Fragment } from 'react';
import {
  useParams,
  useNavigate,
  useSearchParams,
  redirect,
  LoaderFunctionArgs,
  useLoaderData,
} from 'react-router-dom';

import {
  collection,
  query,
  orderBy,
  limit,
  where,
  or,
} from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire';
import { v4 as uuidv4 } from 'uuid';
import { format, isSameDay } from 'date-fns';
import { ko } from 'date-fns/locale';
import { frommClient, getCloudFrontURL, FrommError } from '~/src/fromm';
import type { FrommFirebaseMessage, FrommFriend } from '~/src/fromm';
import {
  Input,
  Button,
  Chip,
  Switch,
  ScrollShadow,
  Textarea,
} from '@nextui-org/react';

export async function loader({ params }: LoaderFunctionArgs) {
  let fbOnly = localStorage.getItem('fbOnly') === 't';
  if (fbOnly) {
    return null;
  }
  try {
    if (!frommClient.userInfo) {
      await frommClient.refreshToken();
    }
    let friend = await frommClient.star(params.friendId!);
    return friend;
  } catch (error) {
    if (error instanceof FrommError) {
      throw redirect('/login');
    } else {
      throw error;
    }
  }
}

function AudioMessagePlayer({ message }: { message: FrommFirebaseMessage }) {
  let [audioEl, setAudioEl] = useState<HTMLAudioElement | null>(null);
  let [isPlaying, setIsPlaying] = useState(false);

  function handlePlay() {
    if (audioEl && !audioEl.paused) {
      audioEl.pause();
    } else if (audioEl) {
      audioEl.play();
    } else {
      let audioEl = new Audio(getCloudFrontURL(message.thumbnail!).href);
      setAudioEl(audioEl);
      audioEl.play();
    }
  }

  let [progress, setProgress] = useState(0);
  useEffect(() => {
    function handleTimeUpdate() {
      if (!audioEl) return;
      if (audioEl.currentTime === audioEl.duration) {
        setAudioEl(null);
        setProgress(0);
      } else {
        setProgress((audioEl.currentTime / audioEl.duration) * 100);
      }
    }
    function handlePlayPause() {
      if (!audioEl) return;
      setIsPlaying(!audioEl.paused);
    }
    audioEl?.addEventListener('timeupdate', handleTimeUpdate);
    audioEl?.addEventListener('play', handlePlayPause);
    audioEl?.addEventListener('pause', handlePlayPause);
    return () => {
      audioEl?.removeEventListener('timeupdate', handleTimeUpdate);
      audioEl?.removeEventListener('play', handlePlayPause);
      audioEl?.removeEventListener('pause', handlePlayPause);
    };
  }, [audioEl]);

  return (
    <div className='w-48 h-12 relative'>
      <div
        className='absolute inset-0 bg-primary-500/25 transition-transform ease-linear'
        style={{
          transform: `translateX(-${
            (100 - progress) / 2
          }%) scaleX(${progress}%)`,
        }}
      />
      <div className='absolute end-4 inset-y-2 text-xs text-gray-500 leading-8'>
        {Math.round((progress * message.runningTime!) / 100)}s{' / '}
        {message.runningTime!}s
      </div>
      <button
        className='absolute start-4 inset-y-0 text-gray-500'
        onClick={handlePlay}
      >
        <svg
          className='w-6 h-6 mb-[1px]'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          viewBox='0 0 24 24'
          stroke='currentColor'
          fill='currentColor'
        >
          {isPlaying ? (
            <>
              <rect x='8' y='6' width='2' height='12' />
              <rect x='14' y='6' width='2' height='12' />
            </>
          ) : (
            <polygon points='6,6 18,12 6,18' />
          )}
        </svg>
      </button>
    </div>
  );
}

function MessageContent({
  message,
  translatedText,
}: {
  message: FrommFirebaseMessage;
  translatedText: string;
}) {
  if (message.type === 'text') {
    let Component: 'span' | 'del' = !message.deleted ? 'span' : 'del';
    return (
      <>
        <p className={translatedText ? 'px-4 pt-2 pb-1' : 'px-4 py-2'}>
          {message.content.split('\n').map((line, index) => (
            <Component
              key={index}
              className={!message.deleted ? '' : 'decoration-1'}
            >
              {index !== 0 && <br />}
              {line}
            </Component>
          ))}
        </p>
        {translatedText && (
          <p className='px-4 pt-1 pb-2 border-t border-gray-300 text-gray-500'>
            {translatedText.split('\n').map((line, index) => (
              <span key={index}>
                {index !== 0 && <br />}
                {line}
              </span>
            ))}
          </p>
        )}
      </>
    );
  } else if (message.type === 'sound') {
    return <AudioMessagePlayer message={message} />;
  } else if (message.type === 'image') {
    return (
      <img
        src={getCloudFrontURL(message.content).href}
        className='h-80 object-contain'
      />
    );
  } else if (message.type === 'video') {
    return (
      <video
        controls
        src={getCloudFrontURL(message.content).href}
        className='h-80'
      />
    );
  } else {
    console.error(`${message.type} messages are not yet supported.`);
    return <p>{message.content}</p>;
  }
}

function Message({
  friend,
  message,
}: {
  friend: FrommFriend | null;
  message: FrommFirebaseMessage;
}) {
  let isStarMessage = message.userType === 'star';
  let createdAtDate = message.createdAt.toDate();
  let formattedTime = createdAtDate.toLocaleTimeString();
  let [searchParams, setSearchParams] = useSearchParams();
  let user = useUser().data;

  // TODO: move this translation logic back to MessageContent if possible
  let [translatedText, setTranslatedText] = useState('');
  let [isTranslating, setIsTranslating] = useState(false);
  async function translate(text: string, targetLang = 'KO') {
    setIsTranslating(true);
    try {
      let response = await fetch(
        'https://deepl-cors.goranmoomin.dev/v2/translate',
        {
          method: 'POST',
          body: new URLSearchParams({
            text: text,
            target_lang: targetLang,
          }),
        },
      );
      let json = await response.json();
      setTranslatedText(json.translations[0].text);
    } catch (error) {
      console.error('Translation error:', error);
      setTranslatedText('');
    } finally {
      setIsTranslating(false);
    }
  }

  return (
    <div
      className={`flex ${
        isStarMessage ? 'justify-start' : 'justify-end'
      } gap-2`}
    >
      {isStarMessage && (
        <img
          src={
            getCloudFrontURL(
              friend?.profileUrlThumbnail || friend?.profileUrl || '',
            ).href
          }
          alt={`Profile of ${friend?.nick}`}
          className='w-10 h-10 rounded-full object-cover flex-none'
        />
      )}
      {!isStarMessage && (
        <div className='shrink-0 text-xs text-gray-400 flex flex-col gap-1 self-end'>
          <div className='text-end'>
            {user?.uid === message.userId ? (
              <>
                {message.fanNick ?? ''}
                {message.blocked && ' (차단됨)'}
              </>
            ) : (
              <button
                className='ms-1'
                onClick={() =>
                  setSearchParams({ ...searchParams, userId: message.userId })
                }
              >
                {message.fanNick ?? ''}
                {message.blocked && ' (차단됨)'}
              </button>
            )}
          </div>
          <div>{formattedTime}</div>
        </div>
      )}
      <div className='flex flex-col gap-2'>
        {isStarMessage && (
          <div className='text-xs text-gray-400'>{friend?.nick}</div>
        )}
        <div
          className={`rounded-2xl overflow-hidden ${
            isStarMessage
              ? 'rounded-ss-none bg-gray-200 text-black'
              : 'rounded-se-none bg-primary-500 text-white'
          }`}
        >
          <MessageContent message={message} translatedText={translatedText} />
        </div>
      </div>
      {isStarMessage && (
        <div className='shrink-0 text-xs text-gray-400 flex flex-col gap-1 self-end'>
          {message.deleted && <div>(삭제됨)</div>}
          {message.type === 'text' && (
            <button
              className='text-start'
              onClick={() => translate(message.content)}
              disabled={isTranslating}
            >
              {isTranslating ? '(번역중…)' : '(번역하기)'}
            </button>
          )}
          <div>{formattedTime}</div>
        </div>
      )}
    </div>
  );
}

let Messages = memo(function Messages({
  friend,
  messages,
}: {
  friend: FrommFriend | null;
  messages: FrommFirebaseMessage[];
}) {
  messages = Array.from(messages).reverse();
  let nodes: React.ReactNode[] = [];
  let currentDate: Date | undefined;
  for (let message of messages) {
    if (!currentDate || !isSameDay(currentDate, message.createdAt.toDate())) {
      nodes.push(
        <Fragment key={message.id}>
          <Chip variant='flat' className='mx-auto my-2 text-xs text-gray-500'>
            {format(message.createdAt.toDate(), 'PPP', { locale: ko })}
          </Chip>
          <Message friend={friend} message={message} />
        </Fragment>,
      );
    } else {
      nodes.push(
        <Message key={message.id} friend={friend} message={message} />,
      );
    }
    currentDate = message.createdAt.toDate();
  }
  return <div className='flex flex-col gap-4 my-2'>{nodes}</div>;
});

export default function Chat() {
  let { friendId } = useParams() as { friendId: string };
  let friend = useLoaderData() as FrommFriend | null;
  let [message, setMessage] = useState('');
  let [allowNewlines, setAllowNewlines] = useState(false);
  let [messageCount, setMessageCount] = useState(200);
  let [sendLimit, setSendLimit] = useState(3);
  let [lengthLimit, setLengthLimit] = useState(100);
  let [displayMode, setDisplayMode] = useState<'default' | 'star'>('default');
  let [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  let [isSending, setIsSending] = useState(false);
  let messageInputRef = useRef<HTMLInputElement>(null!);
  let navigate = useNavigate();

  useEffect(() => {
    if (message.match(/^setmessagecount (\d+)$/)) {
      let [, newMessageCount] = message.match(/^setmessagecount (\d+)$/)!;
      setMessageCount(parseInt(newMessageCount, 10));
    }
  }, [message]);

  let chatContainerRef = useRef<HTMLDivElement>(null!);

  let firestore = useFirestore();
  let user = useUser().data;
  let [searchParams] = useSearchParams();
  let messagesQuery = query(
    collection(firestore, 'root-collection', friendId, 'message'),
    orderBy('createdAt', 'desc'),
    limit(messageCount),
  );

  useEffect(() => {
    if (searchParams.get('userId')) {
      setDisplayMode('default');
    }
  }, [searchParams.get('userId')]);

  let userId = searchParams.get('userId') ?? user?.uid;
  if (displayMode === 'default') {
    messagesQuery = query(
      messagesQuery,
      or(where('userId', '==', userId), where('userId', '==', friendId)),
    );
  } else if (displayMode === 'star' && localStorage.getItem('fbOnly') !== 't') {
    messagesQuery = query(messagesQuery, where('userId', '==', friendId));
  } else {
    messagesQuery = query(messagesQuery, where('userId', '==', userId));
  }

  let messages = useFirestoreCollectionData(messagesQuery, { idField: 'id' })
    .data as FrommFirebaseMessage[];

  let isSendDisabled =
    isSending ||
    message === '' ||
    message.length > lengthLimit ||
    !messages.slice(0, sendLimit).some(message => message.userType === 'star');

  useEffect(() => {
    if (shouldAutoScroll) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages[0]?.id, shouldAutoScroll]);

  async function handleSendMessage(e: React.FormEvent) {
    e.preventDefault();
    try {
      setIsSending(true);
      // let forbiddenWords = await frommClient.getForbiddenWords();
      // for (let forbiddenWord of forbiddenWords) {
      //   let forbiddenWordStartIndex = message.indexOf(forbiddenWord);
      //   if (forbiddenWordStartIndex !== -1) {
      //     setIsSending(false);
      //     // FIXME: this does not work in Chrome for some reason
      //     // FIXME: this is not effective in mobile devices at all
      //     await new Promise(resolve => setTimeout(resolve, 0));
      //     messageInputRef.current.setSelectionRange(
      //       forbiddenWordStartIndex,
      //       forbiddenWordStartIndex + forbiddenWord.length,
      //     );
      //     return;
      //   }
      // }
      await frommClient.sendChatMessage(
        friendId,
        message,
        uuidv4().toUpperCase(),
      );
      setMessage('');
    } catch (error) {
      if (error instanceof FrommError && error.code === 100) {
        navigate('/login');
      } else {
        throw error;
      }
    } finally {
      setIsSending(false);
    }
  }

  useEffect(() => {
    if (!isSending) {
      messageInputRef.current.focus();
    }
  }, [isSending]);

  return (
    <div className='flex flex-col gap-1 mx-auto max-w-xl p-2 h-full'>
      <ScrollShadow className='flex-1' ref={chatContainerRef}>
        <Button
          className='block mx-auto mt-2 font-medium'
          radius='full'
          isDisabled={!(messageCount < 10000)}
          onClick={() => setMessageCount(messageCount + 200)}
        >
          Load More Messages &uarr;
        </Button>
        <Messages friend={friend} messages={messages} />
      </ScrollShadow>
      <div className='flex mb-2 gap-2'>
        <Switch
          size='sm'
          isSelected={displayMode === 'star'}
          onValueChange={isSelected =>
            setDisplayMode(isSelected ? 'star' : 'default')
          }
        >
          스타만 표시
        </Switch>
        <Switch
          size='sm'
          isSelected={sendLimit !== 3 && lengthLimit !== 100}
          onValueChange={isSelected => {
            setSendLimit(isSelected ? 1000 : 3);
            setLengthLimit(isSelected ? Infinity : 100);
          }}
        >
          전송 제한 제거
        </Switch>
        <Switch
          size='sm'
          isSelected={shouldAutoScroll}
          onValueChange={setShouldAutoScroll}
        >
          자동 스크롤
        </Switch>
        <Switch
          size='sm'
          isSelected={allowNewlines}
          onValueChange={setAllowNewlines}
        >
          줄바꿈 입력
        </Switch>
      </div>
      <form
        className='flex gap-2 pb-2'
        onSubmit={handleSendMessage}
        autoComplete='off'
      >
        {allowNewlines ? (
          <Textarea
            ref={messageInputRef}
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder='Type your message here…'
            className='resize-none'
            classNames={{
              label: 'pb-0',
              input: 'text-base',
              helperWrapper: 'p-0',
            }}
            disabled={isSending}
            autoFocus
          />
        ) : (
          <Input
            ref={messageInputRef}
            type='text'
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder='Type your message here…'
            classNames={{ input: 'text-base' }}
            disabled={isSending}
            autoFocus
          />
        )}
        <Button
          type='submit'
          color='primary'
          className='font-medium h-full'
          isDisabled={isSendDisabled}
          isLoading={isSending}
        >
          Send
        </Button>
      </form>
    </div>
  );
}
